

































































































































































































































































































































































































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import {
  getMenuTree,
  getpostQuestionList,
  getDisease,
  delDisease,
  getDetailDisease,
} from '@/serve/evaluatingBefore/evaluatingQuestion';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage';
interface DynamicTags {
  quesOptionContent: string;
  sort: string | number;
  quesOptionRelaVOList: QuesOptionRelaVOList[];
  id?: number | string;
}
interface QuesOptionRelaVOList {
  diseaseScore: number | string; //测评指数
  diseaseId: string | number; //疾病系统id
  id?: number;
}
interface AddData<T, U> {
  quesDesc: T;
  quesKey: T;
  quesName: T;
  quesType: T;
  id?: U;
  quesSubjectList: {
    quesSubjectContent: T;
    quesSubjectIsRequired: T;
    quesSubjectType: T;
    sort: T | U;
    id?: U;
    quesId?: U;
    quesOptionList: {
      quesOptionContent: T; //问卷选项内容
      sort: T | U; //排序
      quesOptionRelaVOList: {
        diseaseScore: T; //测评指数
        diseaseId: T | U; //疾病系统id
      }[];
    }[];
  }[];
}
// interface QuestionData<T, U> {
//   quesDesc: T; //问卷描述
//   quesKey: T; // 问卷关键字
//   quesName: T; // 问卷名称
//   quesType: T; //问卷类型
//   id?: T | U; //问卷id
// }
interface TopicData<T, U> {
  check?: T | boolean;
  quesSubjectContent: T;
  quesSubjectIsRequired: T;
  quesSubjectType: T;
  sort: T | U;
  id?: U;
  quesId?: U;
  arr?: (T | U)[];
  item?: T | undefined;
  itemSort?: T | U | undefined;
  diseaseType?: T | undefined;
  scoreModel?: T | U | undefined;
  projectList?: QuesOptionRelaVOList[];
  clearList?: DynamicTags[];
  quesOptionList: DynamicTags[];
  [propName: string]: any;
}
@Component({
  name: 'evaluatingQuestionCreate',
})
export default class EvaluatingQuestionCreate extends Vue {
  public currentPage4 = 1;
  public formLabelWidth = '120px';
  public tableData = [];
  public diseaseType = '';
  public checkQuestion = true;
  public scoreModel = '';
  public riskModel = '';
  public resData: TopicData<string, number>[] = [];
  // public quesOptionRelaVOList: QuesOptionRelaVOList[] = [];
  public rules = {
    quesName: [{ required: true, message: '请填写问卷名称', trigger: 'blur' }],
    quesDesc: [],
    quesKey: [],
    quesType: [
      { required: true, message: '请选择问卷类型', trigger: 'change' },
    ],
  };
  public formData = {
    quesName: '',
    quesDesc: '',
    quesType: '检前评估',
    quesKey: '',
    quesSubjectList: this.resData,
  };
  public tenantName = '';
  public isAdmin = sessionStorage.getItem('isAdmin');
  public restaurants = [];
  public options = [
    {
      value: '单选',
      label: '单选',
    },
    {
      value: '多选',
      label: '多选',
    },
  ];
  // public typeOptions = [
  //   {
  //     value: '检前评估',
  //     label: '检前评估',
  //   },
  //   {
  //     value: '健康测评',
  //     label: '健康测评',
  //   },
  // ];
  public form = {
    size: 10,
    current: 1,
    quesName: '',
    tenantId: '',
  };
  public addNum = 1;
  public editNum = 1;
  public dynamicTags: DynamicTags[] = [];
  public inputVisible = false;
  public inputValue = '';
  public value = '';
  public liIsShow = false;
  public dialogFormVisible = false;
  public disease = [];
  public topicSort = '';
  public itemSort = '';
  public typeInter = '';
  public async created(): Promise<void> {
    this.getQuestionList();
    this.getDiseaseList();
  }
  public mounted() {
    this.getTenantData();
  }
  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.form.tenantId = e.tId;
    else this.form.tenantId = '';
  }
  // 筛选按钮
  public onSubmit() {
    this.getQuestionList();
  }
  // 重置功能
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();
    this.tenantName = '';
    this.form.tenantId = '';
    this.currentPage4 = 1;
    this.getQuestionList();
    this.getDiseaseList();
  }
  // 获取疾病系统
  public async getDiseaseList() {
    // const data = {
    //   diseaseType: '疾病',
    // };
    const res = await getDisease();
    this.disease = res.data.records;
  }
  // 获取问卷题目列表
  public async getQuestionList() {
    this.$changNull(this.form);
    const res = await getMenuTree(this.form);
    this.tableData = res.data;
  }
  // 编辑按钮
  public async editRow(id: number): Promise<void> {
    this.typeInter = '编辑';
    console.log("前", this.formData);
    const res = await getDetailDisease(id);
    this.checkQuestion = !this.checkQuestion;
    this.formData = res.data;
    console.log("后", this.formData);
    this.addNum = this.formData.quesSubjectList.length + 1;
    let resData;
    for (const key in this.formData.quesSubjectList) {
      resData = this.formData.quesSubjectList[key];
      this.$set(resData as any, 'projectList', []);
      this.$set(resData as any, 'arr', []);
      this.$set(resData as any, 'clearList', resData.quesOptionList);
    }
    console.log("resData",resData);
  }
  // 删除按钮
  public async deleteRow(id: number, row: any): Promise<void> {
    (this as any).$messageBox
      .confirm(`您确定是否删除问卷名称为${row.quesName}的这一条数据`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      .then(async () => {
        const res = await delDisease(id);
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getQuestionList();
          this.getDiseaseList();
        }
      });
  }
  /**头部查询搜索机构事件 */
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.form.tenantId = '';
  }
  public handleSizeChange(val: any): void {
    this.form.size = val;
    this.getQuestionList();
    this.getDiseaseList();
  }
  public handleCurrentChange(val: any): void {
    this.form.current = val;
    this.getQuestionList();
    this.getDiseaseList();
  }
  // 添加题目
  public addTopic() {
    // let sort;
    // this.typeInter === '编辑' ? (sort = this.addNum) : (sort = this.editNum);
    const addData: TopicData<string, number> = {
      check: '',
      quesSubjectContent: '', //题目内容
      quesSubjectIsRequired: '', //题目是否必填
      quesSubjectType: '', //题目类型
      sort: this.addNum, //排序号
      projectList: [],
      arr: [],
      clearList: [],
      addDisabled: false,
      tagIndex: '',
      quesOptionList: [
        {
          quesOptionContent: '', //问卷选项内容
          sort: '', //排序
          quesOptionRelaVOList: [
            {
              diseaseScore: '', //测评指数
              diseaseId: '', //疾病系统id
            },
          ],
        },
      ],
    };
    //题目列表
    (this.formData.quesSubjectList as TopicData<string, number>[]).push(
      addData
    );
    this.addNum++;
  }
  // 新增选项方法
  public addItem(index: number) {
    const resData = this.formData.quesSubjectList[index];
    if (
      resData.check === '' ||
      resData.quesSubjectIsRequired === '' ||
      resData.quesSubjectType === '' ||
      resData.quesSubjectContent === '' ||
      resData.sort === ''
    ) {
      this.$message({
        message: '请先将题目信息填写完整',
        type: 'warning',
      });
    } else if (resData.item && resData.itemSort) {
      console.log('resData.clearList', resData.clearList);
      // 由于vue监听不到数组的直接赋值 那么用foreach + push进想要的数组进行
      (resData.clearList as DynamicTags[]).push({
        quesOptionContent: resData.item as string,
        sort: resData.itemSort as string | number,
        quesOptionRelaVOList: resData.projectList as any[],
      });
      resData.quesOptionList = [];
      console.log('resData.clearList', resData.clearList);

      (resData.clearList as DynamicTags[]).forEach((element) => {
        resData.quesOptionList.push(element);
      });
      this.refreshData(index);
      // 序号随着选项保存增加而增加
      resData.itemSort = resData.quesOptionList.length + 1;
    }
  }
  // 选项删除按钮
  public delItem(index: number, indexs: number | string) {
    const resData = this.formData.quesSubjectList[index];
    console.log(resData.quesOptionList);
    resData.quesOptionList.splice(Number(indexs), 1);
    if (resData.quesOptionList.length === 0) {
      resData.addDisabled = false;
    }
    this.refreshData(index);
  }
  // 这是重置数据
  public refreshData(index: number) {
    const resData = this.formData.quesSubjectList[index];

    this.$set(resData, 'item', '');
    this.$set(resData, 'itemSort', '');
    this.$set(resData, 'diseaseType', '');
    this.$set(resData, 'scoreModel', '');
    this.$set(resData, 'arr', []);
    this.$set(resData, 'projectList', []);
  }

  // 选择框改变事件 思路select里的值推进一个数组 如果数组里找不到这个值则push新的初始化对象 如果有那么当输入框里值改变了进行复制操作
  public changeDisease(value: number, index: number) {
    const resData = this.formData.quesSubjectList[index];
    resData.diseaseType = this.filterName(value);

    if ((resData.projectList as QuesOptionRelaVOList[]).length === 0) {
      (resData.projectList as QuesOptionRelaVOList[]).push({
        diseaseScore: '',
        diseaseId: value,
      });
      (resData.arr as (string | number)[]).push(value);
    }
    for (const key in resData.projectList as QuesOptionRelaVOList[]) {
      // 如果arr数组里 疾病id存在进行操作
      if ((resData.arr as (string | number)[]).includes(value)) {
        if (
          (resData.projectList as QuesOptionRelaVOList[])[key].diseaseScore ===
          ''
        ) {
          this.$set(resData, 'scoreModel', '');
        }
      } else {
        // 不能存在那么将新的id存进去 并且projectList push一个新的id值的对象
        (resData.arr as (string | number)[]).push(value);
        (resData.projectList as QuesOptionRelaVOList[]).push({
          diseaseId: value,
          diseaseScore: '',
        });
      }
    }
    // 循环遍历projectList数组 如果它里面的id值等于当前疾病名称id 那么scoreModel（绑定分数的值）将被赋值projectList下的diseaseScore值
    for (const key in resData.projectList as QuesOptionRelaVOList[]) {
      if (
        (resData.projectList as QuesOptionRelaVOList[])[key].diseaseId === value
      ) {
        (resData.scoreModel as
          | number
          | string) = (resData.projectList as QuesOptionRelaVOList[])[
          key
        ].diseaseScore;
      }
    }
    // const quesDiv = document.getElementsByClassName('questionItemDiv_first');
  }
  // 当输入框值改变的时候 绑定的值也跟着改变
  public scoreChange(value: number, index: number) {
    const resData = this.formData.quesSubjectList[index];
    let distype = '';
    console.log(resData.diseaseType);
    for (const key in this.disease) {
      if ((this.disease[key] as any).diseaseName === resData.diseaseType) {
        distype = (this.disease[key] as any).id;
      }
    }
    console.log(resData.projectList);
    console.log(distype);

    // this.riskModel值是改变疾病框后存起来的值 如果projectList数组里的id值等于riskModel 那么diseaseScore 就等于传入的value（分数）
    for (const key in resData.projectList as QuesOptionRelaVOList[]) {
      if (
        (resData.projectList as QuesOptionRelaVOList[])[key].diseaseId ===
        distype
      ) {
        (resData.projectList as QuesOptionRelaVOList[])[
          key
        ].diseaseScore = value;
      }
    }
  }

  // 题目类型改变
  public itemChange(value: string, index: number) {
    const resData = this.formData.quesSubjectList[index];

    resData.check = value;
    this.refreshData(index);
    this.$set(resData, 'projectList', []);
  }
  // 题目保存
  public topicSave(index: number) {
    console.log(index);
  }
  // 题目删除
  public topicDel(index: number) {
    this.formData.quesSubjectList.splice(index, 1);
  }
  // public personData() {
  //   // 新增
  //   const addData = {
  //     quesDesc: '', //问卷描述
  //     quesKey: '', // 问卷关键字
  //     quesName: '', // 问卷名称
  //     quesType: '检前评估', //问卷类型
  //     quesSubjectList: [
  //       {
  //         quesSubjectContent: '', //题目内容
  //         quesSubjectIsRequired: '', //题目是否必填
  //         quesSubjectType: '', //题目类型
  //         sort: '', //排序号
  //         quesOptionList: [
  //           {
  //             quesOptionContent: '', //问卷选项内容
  //             sort: '', //排序
  //             quesOptionRelaVOList: [
  //               {
  //                 diseaseScore: '', //测评指数
  //                 diseaseId: '', //疾病系统id
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ], //题目列表
  //   };
  //   //编辑
  //   const editData = {
  //     quesDesc: '', //问卷描述
  //     quesKey: '', // 问卷关键字
  //     quesName: '', // 问卷名称
  //     quesType: '检前评估', //问卷类型
  //     id: '', //问卷id
  //     quesSubjectList: [
  //       {
  //         quesSubjectContent: '', //题目内容
  //         quesSubjectIsRequired: '', //题目是否必填
  //         quesSubjectType: '', //题目类型
  //         sort: '', //排序号
  //         id: '', //题目id
  //         quesId: '', //问卷id
  //         quesOptionList: [
  //           {
  //             quesOptionContent: '', //问卷选项内容
  //             sort: '', //排序
  //             quesOptionRelaVOList: [
  //               {
  //                 diseaseScore: '', //测评指数
  //                 diseaseId: '', //疾病系统id
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ], //题目列表
  //   };
  // }
  // 点击选项列表
  public tagClick(items: DynamicTags, index: number, indexs: number | string) {
    console.log(items);

    const resData = this.formData.quesSubjectList[index];
    this.$set(resData, 'tagIndex', Number(indexs));
    this.$set(resData, 'uid', (items as any).id ? (items as any).id : null);
    this.$set(resData, 'addDisabled', true);
    const arr = [];
    for (const key in items.quesOptionRelaVOList) {
      arr.push(items.quesOptionRelaVOList[key].diseaseId);
    }
    this.$set(resData, 'arr', arr);
    this.$set(resData, 'item', items.quesOptionContent);
    this.$set(resData, 'itemSort', items.sort);
    // const newArr: any[] = [];
    // resData.quesOptionList.forEach((e) => {
    //   newArr.push(e);
    // });
    this.$set(resData, 'projectList', items.quesOptionRelaVOList);
    if ((resData.projectList as []).length === 0) {
      resData.diseaseType = '';
      resData.scoreModel = '';
    }
    if (items.quesOptionRelaVOList.length > 0) {
      const diseaseId = items.quesOptionRelaVOList[0].diseaseId;
      for (const key in this.disease) {
        if (diseaseId === (this.disease[key] as any).id) {
          this.$set(
            resData,
            'diseaseType',
            (this.disease[key] as any).diseaseName
          );
        }
      }
      this.$set(
        resData,
        'scoreModel',
        items.quesOptionRelaVOList[0].diseaseScore
      );
    }
  }
  // 加号按钮
  public tagAddClick(index: number) {
    this.formData.quesSubjectList[index].addDisabled = false;
    this.refreshData(index);
  }
  // 问卷提交按钮
  public async postQuestion() {
    // formData
    (this.$refs['formData'] as ElForm).validate(async (valid) => {
      if (valid) {
        const res = await getpostQuestionList(this.formData);
        if (res.code === 200) {
          this.$message({
            message: '提交成功',
            type: 'success',
          });
          this.getQuestionList();
          this.checkQuestion = !this.checkQuestion;
          this.formData = {
            quesName: '',
            quesDesc: '',
            quesType: '检前评估',
            quesKey: '',
            quesSubjectList: [],
          };
        } else {
          this.$message({
            message: res.msg,
            type: 'error',
          });
        }
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
  // 疾病名字筛选
  public filterName(data: string | number) {
    for (const key in this.disease) {
      if ((this.disease[key] as any).id === data) {
        return (this.disease[key] as any).diseaseName;
      }
    }
  }
  // 添加问卷按钮
  public addClearQuestion() {
    this.checkQuestion = !this.checkQuestion;
    this.formData = {
      quesName: '',
      quesDesc: '',
      quesType: '检前评估',
      quesKey: '',
      quesSubjectList: [],
    };
    this.typeInter = '新增';
    this.addNum = 1;
  }
  // 删除选项中某项疾病
  public delRisk(index: number, liIndex: number) {
    const resData = this.formData.quesSubjectList[index];
    (resData.projectList as []).splice(liIndex, 1);
    (resData.arr as []).splice(liIndex, 1);
    resData.diseaseType = '';
    resData.scoreModel = '';
    console.log(resData);
  }
}
